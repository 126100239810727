import { render, staticRenderFns } from "./state_pingtai.vue?vue&type=template&id=31c23e85&scoped=true&"
import script from "./state_pingtai.vue?vue&type=script&lang=js&"
export * from "./state_pingtai.vue?vue&type=script&lang=js&"
import style0 from "./state_pingtai.vue?vue&type=style&index=0&id=31c23e85&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c23e85",
  null
  
)

export default component.exports